<script>
import {authMethods} from "@/state/helpers";
import appConfig from "@/app.config";

/**
 * Forgot password component
 */
export default {
  page: {
    title: "Zapomniałeś hasła?",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      info: true,
      email: "",
      error: null,
      success: null,
      tryingToReset: false,
      isResetSuccess: false,
      isResetError: false,
      footerYear: new Date().getFullYear()
    };
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.tryingToReset = true;
      // Reset the authError if it existed.

      if (this.email.length < 6) {
        return false;
      }

      return (
        this.resetPassword({
          email: this.email
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToReset = false;
            this.email = null;
            this.success = 'Jeżeli e-mail jest prawidłowy, to za chwilę otrzymasz wiadomość z dalszymi instrukcjami.';
            this.isResetSuccess = true;
            this.isResetError = false;
          })
          .catch(error => {
            this.tryingToReset = false;
            if (error instanceof TypeError) {
              this.error = 'Komunikacja z serwerem nie powiodła się. Sprawdź stan połączenia internetowego oraz z siecią VPN i spróbuj ponownie.'
            } else {
              this.error = error ? error : "";
            }
            this.isResetError = true;
          })
      );
    },
    decryptMailtoEmail(encoded) {
      var address = atob(encoded);
      window.location.href = "mailto:" + address;
    }
  }
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20 p-2">Resetowanie hasła - krok 1</h5>
                <a href="/" class="logo logo-admin">
                  <img src="@/assets/images/si-pay-logo.png" height="24" alt="logo" />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3 mt-4">
                <b-alert v-model="isResetError" class="mt-3 mt-2" variant="danger" dismissible>{{error}}</b-alert>
                <b-alert v-model="isResetSuccess" class="mt-3 mt-2" variant="success" dismissible>{{success}}</b-alert>
                <b-form @submit.prevent="tryToReset" class="form-horizontal mt-4">
                  <b-form-group id="email-group" label="E-mail" label-for="email">
                    <b-form-input id="email" v-model="email" type="email" placeholder="Wpisz adres e-mail"></b-form-input>
                  </b-form-group>

                  <div class="form-group row mb-0">
                    <div class="col-12 text-right">
                      <b-button type="submit" variant="primary" class="w-md">Resetuj hasło</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Pamiętasz swoje hasło?
              <router-link tag="a" to="/login" class="font-weight-medium text-primary">Zaloguj się</router-link>
            </p>
            <p class="mb-0">
              © {{new Date().getFullYear()}} <img src="@/assets/images/si-pay-logo.png" alt height="15" class="mt-n1" /> SI-PAY
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
